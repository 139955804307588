<template>
<div class="obj-list wrapper ofh" v-if="!loading" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <div>
            <h1>{{ $store.state.me.organization? `"${$store.state.me.organization.name_cyr}" - ${$t('оbjectsi')}` :$t('all_object_list')  }} </h1>
        </div>
       
    </div>
    <div class="wp-body">
        <header class="wp-header w-full">
            <div class="flex-1   grid grid-cols-12  gap-3"> 
                    <div v-if="['admin', 'ministry'].includes(me.role.name)" class="col-span-3">
                        <el-select class="w-full"  v-model="region"  @change="regionChanged(); __GET();" :placeholder="$t('region')" clearable>
                            <el-option v-for="item in regions" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.shared_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="['admin', 'ministry'].includes(me.role.name)" class="col-span-3">
                        <el-select class="w-full"  v-model="district" @change="__GET()" :disabled="!region" :placeholder="$t('district')"   clearable>
                            <el-option v-for="item in districts" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex items-center col-span-3">
                        <el-input class="search" style="margin-left: 0 !important; flex: 1" :placeholder="$t('search')" prefix-icon="el-icon-search" v-model="search" @keyup.enter.native="__GET()" clearable>
                        </el-input>
                        <el-button @click="__GET()" type="primary" icon="el-icon-search" class="ml-2" size="small" circle></el-button>
                    </div>
                </div> 
                <button v-if="!['admin', 'org_admin','ministry_admin'].includes(me.role.name)"  id="object-create" class="button dark-yellow mr-1" @click="$router.push('/objects/object-create')"> <i class="el-icon-plus mr-1"></i> {{$t('add_new_object')}}</button>
                
        </header>
        <el-table :data="objList" class="my-5" style="width: 99%;" row-key="id">
            <el-table-column label="№" width="100px" align="center">
                <template slot-scope="scope">
                    {{ (current_page - 1) * item_count + (scope.$index + 1) }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('cadaster_number')" width="190" align="center" sortable>
                <template slot-scope="scope">
                    <div>
                        <router-link  :to="`/objects/object-info/${scope.row.id}`" class="router-link">{{ scope.row.cadastral_number }}  <i class="el-icon-top-right"></i> </router-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="['admin', 'org_admin', 'ministry_admin'].includes(me.role.name)" width="250px" prop="organization.name_cyr" :label="$t('organisation_name')" align="center">
            </el-table-column>
            <el-table-column width="150px" :label="$t('year_construction')" prop="year_construction" align="center">
            </el-table-column>
            <el-table-column width="150px" :label="$t('add_building_count')" prop="building_count" align="center" sortable>
            </el-table-column>
            <el-table-column width="150px" :label="$t('building_count')" prop="number_buildings" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('created_by')" align="center">
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.created_by)">
                        {{scope.row.created_by!=null && scope.row.created_by.profile!=null ? scope.row.created_by.profile.name+" "+scope.row.created_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('updated_by')" align="center">
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.updated_by)">
                        {{scope.row.updated_by!=null && scope.row.updated_by.profile!=null ? scope.row.updated_by.profile.name +" "+ scope.row.updated_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" width="110">
                <template slot-scope="scope">
                    <div class="d-flex justify-center">
                        <!-- <el-button size="small" @click="$router.push({name: 'obj-info',params: { id: scope.row.id, org:scope.row.organization.name_cyr },}) " class="el-icon-view" type="primary" plain></el-button> -->
                        <el-button size="small" @click="$router.push({name: 'building-list-one',params: { id: scope.row.id, org:scope.row.organization.name_cyr },}) " class="el-icon-document el-button-download" ></el-button>
                        <el-button v-if="me.role.name == 'operator' && false" @click="deleteObject(scope.row.id )" size="small" class="el-icon-delete el-button-download danger" ></el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]" :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page1" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>
    </div>
    <div class="modal fs-14" v-if="usermodal">
        <div class="modal-cover"></div>
        <div class="modal-item" style="max-width:500px;">
            <div class="flex justify-between">
                <p class="modal-title ">{{$t('user_date')}}:</p>
            </div>
            <el-row :gutter="24">
                <el-col :md="12" class="fw-600 mb-2">{{$t('first_name')}}:</el-col>
                <el-col :md="12" class="mb-2" v-if="userData.profile&&userData.profile.name">{{userData.profile.name}}</el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :md="12" class="fw-600 mb-2">{{$t('last_name')}}:</el-col>
                <el-col :md="12" class="mb-2">{{userData.profile.surname}}</el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :md="12" class="fw-600 mb-2">{{$t('mid_name')}}:</el-col>
                <el-col :md="12" class="mb-2">{{userData.profile.middlename}}</el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :md="12" class="fw-600 mb-2">{{$t('phone_munber')}}:</el-col>
                <el-col :md="12" class="mb-2">{{userData.profile.phone_number}}</el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :md="12" class="fw-600 mb-2">{{$t('user_email')}}:</el-col>
                <el-col :md="12" class="mb-2">{{userData.profile.email ? userData.profile.email : '-'}}</el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :md="12" class="fw-600 mb-2">{{$t('organisation_name')}}:</el-col>
                <el-col :md="12" class="mb-2">{{userData.organization && userData.organization.name_cyr ? userData.organization.name_cyr : '-'}}</el-col>
            </el-row>

            <div class="w-full modal-inputes flex justify-end">
                <button @click="usermodal=false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i> {{$t('close')}}</button>
            </div>
        </div>
    </div>
</div>

<ScletonPage header="true" v-else />
</template>

<script>
import ScletonPage from '../../components/Scletons/Page.vue'
export default {
    components:{
        ScletonPage
    },
    data() {
        return {

            page_count: 1,
            current_page: 1,
            current_page1: 1,
            isAdmin: false,
            loading: false,
            item_count: 10,
            input2: null,
            objList: [],
            districts: [],
            region: "",
            district: "",
            search: "",
            usermodal: false,
            userData: {
                organization: {},
                profile: {}
            }
        };
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        regions() {
            return this.$store.state.dictionary.region;
        },
    },
    mounted() {
        this.loading = true
        this.current_page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
        this.search = this.$route.query.search ? this.$route.query.search : ''
        this.region = this.$route.query.region && this.$route.query.region != '' ? parseInt(this.$route.query.region) : ''
        this.district = this.$route.query.district ? parseInt(this.$route.query.district) : ''
        this.item_count = this.$route.query.item_count ? this.$route.query.item_count : 10
        if (this.region != '') {
            this.getDistrict()

        }
        this.__GET();
    },
    methods: {
        paginate(val) {
            this.current_page = val;
            this.__GET();
        },
        paginateSize(val) {
            this.item_count = val
            this.__GET();
        },
        __GET() {
            this.loading = true;
            let offset = this.item_count * (this.current_page - 1);

            if (this.$route.query.id) {
                this.isAdmin = true;
                if (this.search != '' || this.region != '' || this.district != '' || this.item_count != 10) {
                    this.$router.push(`/objects?id=${this.$route.query.id}&page=${this.current_page}&region=${this.region}&district=${this.district}&item_count=${this.item_count}&search=${this.search}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                } else {
                    this.$router.push(`/objects?id=${this.$route.query.id}&page=${this.current_page}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                }
                axios.get(
                        `/building/generalinformation/?organization=${this.$route.query.id}&region__shared_id=${this.region}&district=${this.district}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
                    )
                    .then((response) => {
                        this.objList = response.data.results;
                        this.page_count = Math.ceil(response.data.count / this.item_count);
                        this.current_page1 = this.$route.query.page ? parseInt(this.$route.query.page) : 1
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                if (this.search != '' || this.region != '' || this.district != '' || this.item_count != 10) {
                    this.$router.push(`/objects?page=${this.current_page}&region=${this.region}&district=${this.district}&item_count=${this.item_count}&search=${this.search}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                } else {
                    this.$router.push(`/objects?page=${this.current_page}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                }

                axios
                    .get(
                        `/building/generalinformation/?region__shared_id=${this.region}&district=${this.district}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
                    )
                    .then((response) => {
                        this.objList = response.data.results;
                        this.page_count = Math.ceil(response.data.count / this.item_count);
                        this.current_page1 = this.$route.query.page ? parseInt(this.$route.query.page) : 1

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        regionChanged() {
            this.districts = [];
            this.district = "";
            this.getDistrict();
        },

        getDistrict() {
            this.loading = true;
            axios
                .get(`/dictionary/district/?region__shared_id=${this.region}`)
                .then((response) => {

                    this.districts = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        UserModal(a) {
            if (a != null && a.profile != null) {
                this.userData = a
                this.usermodal = true
            }
        },
        deleteObject(id) {
            return this.$confirm(`Сиз ушбу обеъктни ўчирсангиз, обеъктга тегиши бўлган барча бинолар тизимдан ўчирилади. Обекътни ростдан ўчирмоқчимисиз?`, {
                    confirmButtonText: 'Ҳа',
                    cancelButtonText: 'Йўқ',
                })
                .then(() => {
                    axios.delete(`/building/generalinformation/${id}/`)
                        .then((response) => {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Қониқарли. Обеъкт ва унинг бинолари мувоффақиятли ўчирилди.',
                                timer: 3000
                            });
                            this.__GET();
                        })
                        .catch(error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Ўчиришда хатолик юзберди.',
                                timer: 3000
                            });
                        })
                })

        }
    },
};
</script>

<style lang="scss" scoped>
.top-header {
    width: 100%;
    padding: 15px 20px; 
    border-radius: 16px;
    background: #004787;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-header h1 {
     font-weight: 600;
    font-size: 18px;
    color: #fff !important;
}

.title-second-header {
   font-size: 18px;
   font-weight: 600;
   color: #004787 !important;
}


</style>
